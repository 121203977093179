import { getToApsServer,postToApsServer } from './common';
/**---------------------------设计超市----------------------------------
 * APS排程列表
 * @param {*} opt
 */
export async function apsProdPlanList(opt) {
  return await getToApsServer("/nkapi/DesignPlan", opt);
}

/**
 * APS 排程状态修改
 * @param {*} status
 */
export async function saveProdPlanState(id, status) {
  return await postToApsServer(`/nkapi/DesignPlan/${id}?status=${status}`)
}